import React from 'react';

import PropTypes from 'prop-types';

import { useIntl } from '~/techstyle-shared/react-intl';

export const BuilderLocalizedText = ({ text }) => {
  const { locale } = useIntl();

  if (!text) {
    return null;
  }

  if (typeof text === 'string') {
    return <>{text}</>;
  } else {
    return <>{text[locale] || text.Default}</>;
  }
};

BuilderLocalizedText.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ text: PropTypes.string }),
  ]),
};

export default BuilderLocalizedText;
