import React from 'react';

import PropTypes from 'prop-types';

import CtaNode from '~/shared/components/CtaNode';

const BuilderCta = ({ attributes, text, link = '/', variant }) => (
  <CtaNode
    defaultMessage={text}
    targetUrl={link}
    variantStyle={variant}
    {...attributes}
    data-builder-component-type="cta"
  />
);

BuilderCta.propTypes = {
  attributes: PropTypes.object,
  link: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string,
};

export default BuilderCta;
